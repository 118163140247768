function loadSliders() {
	$('.home-gallery__slides').slick({
		centerPadding: '0',
		slidesToShow: 8,
		prevArrow: "<button class='home-gallery__button home-gallery__button--prev' aria-label='Previous' type='button'></button>",
		nextArrow: "<button class='home-gallery__button home-gallery__button--next' aria-label='Previous' type='button'></button>",
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 4
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

sliderScript.addEventListener('load', function () {
	loadSliders();
});

$(document).ready(function () {

	function fixHeights() {
		$('.testimonials__testimonial-link').sameHeight();
	}

	setTimeout(fixHeights, 300);

	$(window).on('resize',
		function () {
			setTimeout(fixHeights, 300);
		});

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

});











